import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import axios from "axios"

import { AddScraper } from './AddScraperBtn'
import StatusSelect from './StatusSelect'
import Pagination from '../../../../Components/Pagination'
import SearchInput from '../../../../Components/SearchInput'

import { Card, Typography } from "@material-tailwind/react"

import { HiTrash } from "react-icons/hi2"
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { HiPencilAlt } from "react-icons/hi";

const TABLE_HEAD = ["Identifier", "Category", "Scraper", "Status", "Scrape By", "Actions"]

export function BrandScrapersTable() {

    const [scrapersData, setScrapersData] = useState([])
    const [isAltered, setIsAltered] = useState(false)
    const [callBackState, setCallBackState] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [onEdit, setOnEdit] = useState(false)

    const [scraperUpdate, setScraperUpdate] = useState({
        status: ''
    })

    const brandsPerPage = 10
    const totalPages = Math.ceil(scrapersData.length / brandsPerPage)
    const indexOfLastRequest = currentPage * brandsPerPage
    const indexOfFirstRequest = indexOfLastRequest - brandsPerPage

    const filteredBrands = scrapersData.filter(item =>
        (item.scraperId && item.scraperId.toString().includes(searchTerm.trim())) ||
        (item.status && item.status.toString().includes(searchTerm.trim())) ||
        (item.name && item.name.toString().includes(searchTerm.trim())) 
    )

    filteredBrands.sort((a, b) => a.scraperId.localeCompare(b.scraperId))
    const brandScraperArr = filteredBrands.slice(indexOfFirstRequest, indexOfLastRequest)

    useEffect(() => {
        axios.get(`/api/webscrapers/all/scrapers`)
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get batches")
            } else {
                setScrapersData(response.data.scrapers)
                //console.log(response.data.scrapers)
            } 
        })
        .catch(error => {
            console.error('Error:', error)
        }) 

    }, [isAltered, callBackState])

    const handleCallBackState = () => { setCallBackState(prevstate => !prevstate) }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const toggleOnEdit = (index) => setOnEdit((prevState => (prevState === index ? false : index)))

    const handleStatusChange = (newStatus) => {
        setScraperUpdate((prev) => ({ ...prev, status: newStatus }))
    }

    const handleUpdateScraper = (scraperId) => {

        const scraperData = {
            status: scraperUpdate.status
        }

        console.log(scraperData)

        axios.put(`/api/webscrapers/update/${scraperId}`, scraperData)
        .then(response => {
            if (response.data && response.data.message) {
                toast.success(response.data.message)
            } else {
                toast.error('Update was unsuccessful. Please try again.')
            }
            setIsAltered(prevState => !prevState)
            setOnEdit(prevState => !prevState)
        })
        .catch(error => {
            toast.error('An error occurred while deleting the scraper.')
            console.error('Error:', error)
        })
    }

    const handleDeleteScraper = (scraperId) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete scraper: ${scraperId}?`)

        if (isConfirmed) {
            axios.delete(`/api/webscrapers/delete/${scraperId}`)
                .then(response => {
                    if (response.data && response.data.message) {
                        toast.success(response.data.message)
                    } else {
                        toast.error('Deletion was unsuccessful. Please try again.')
                    }
                    setIsAltered(prevState => !prevState)
                })
                .catch(error => {
                    toast.error('An error occurred while deleting the scraper.')
                    console.error('Error:', error)
                })
        }
    }

    return (
    <div className="flex flex-col w-full h-full">
        <div className='flex items-center justify-between w-full text-white my-2'>
            <div className='flex items-center flex-row gap-5'>
                <AddScraper handleCallBackState={handleCallBackState}/>
                <SearchInput value={searchTerm} onChange={handleSearch}/>
            </div>
            <p className='font-semibold text-lg mr-10'>Scraper count: <span className='text-blue-500'>{scrapersData.length}</span></p>
        </div>
        <Card className="h-[34rem] w-full bg-[#101927] bg-opacity-50 rounded-b-none overflow-y-auto">
            <table className="w-full min-w-max table-auto text-center">
            <thead>
                <tr>
                {TABLE_HEAD.map((head) => (
                    <th key={head}  className="border-b border-[#374050] bg-[#1E2936] p-4 sticky top-0 z-10">
                    <Typography
                        variant="small"
                        color="white"
                        className="font-normal leading-none opacity-70"
                    >
                        {head}
                    </Typography>
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {brandScraperArr.map((item, index) => {
                    const globalIndex = indexOfFirstRequest + index

                    return (
                        <tr key={item.scraperId} className="even:bg-blue-gray-50/50">
                        <td className="p-4">
                        <Typography variant="small" color="white" className="font-normal">
                            {item.scraperId}
                        </Typography>
                        </td>
                        <td className="p-4">
                        <Typography variant="small" color="white" className="font-normal">
                            {item.category}
                        </Typography>
                        </td>
                        <td className="p-4">
                        <Typography variant="small" color="white" className="font-normal">
                            {item.name}
                        </Typography>
                        </td>
                        <td className="p-4">
                            {onEdit === globalIndex ? 
                            <StatusSelect status={item.status}  onStatusChange={handleStatusChange} />
                            :
                            <Typography variant="small" color="white" className="font-normal">
                            {item.status}
                        </Typography>
                            }
                        </td>
                        <td className="p-4">
                        <Typography variant="small" color="white" className="font-normal">
                            {item.scrapeBy}
                        </Typography>
                        </td>
                        <td className="p-4">
                            {onEdit === globalIndex ? 
                            <>
                                <button className='p-1 mx-1 bg-green-500 rounded-md text-white' onClick={() => handleUpdateScraper(item.scraperId)}>
                                    <FaCircleCheck className='h-5 w-5'/>
                                </button>
                                <button className='p-1 mx-1 bg-red-500 rounded-md text-white' onClick={toggleOnEdit}>
                                    <MdCancel className='h-5 w-5'/>
                                </button>
                            </> : 
                            <>
                                <button className='p-1 mx-1 bg-blue-500 rounded-md text-white' onClick={() => toggleOnEdit(globalIndex)}>
                                    <HiPencilAlt className='h-5 w-5'/>
                                </button>
                                <button className='p-1 mx-1 bg-red-500 rounded-md text-white' onClick={() => handleDeleteScraper(item.scraperId)}>
                                    <HiTrash className='h-5 w-5'/>
                                </button>
                            </>
                            }

                        </td>
                    </tr>
                    )
                })}
            </tbody>
            </table>
        </Card>
        <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
    </div>
)
}