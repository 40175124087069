import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FloatBar from '../../../../Components/FloatBar'
import neural from '../../../../assets/neural-networks.webp'
import { ToastContainer } from 'react-toastify'
import SideButton from '../../../../Components/SideButton'
import Workspace from './Workspace'
import { BrandScrapersTable } from './BrandScrapersTable'

import { MdWebhook } from "react-icons/md";
import { BsClipboardDataFill } from "react-icons/bs";
import { DiAtom } from "react-icons/di";
import { DiDebian } from "react-icons/di";



const WebScrapers = () => {

  const [view, setView] = useState('WorkSpace')

  const handleSetViews = (view) => {
    setView(view)
  }

  return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-y-auto'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.60), rgba(17, 24, 38, 0.8)), url(${neural})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

      <FloatBar/>
      <div className='flex flex-row gap-2 w-full h-full'>
      <SideButton/>
      <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
        <ToastContainer/>
        <div className='w-full h-fit flex justify-center p-4 text-white'>
          <p className=' text-xl'>Web Scrapers</p>
        </div>

        <div className='w-full h-fit flex flex-row justify-between gap-8 text-white pl-4 p-3'>
          <div className='flex items-center gap-6 '>
          <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10' 
            onClick={() => handleSetViews('WorkSpace')}>
            Workspace</button>
          <button className='p-1 bg-lime-500 hover:bg-lime-600 text-black flex items-center rounded-sm' 
            onClick={() => handleSetViews('ScraperView')}>
            <MdWebhook className='h-5 w-5 mr-1'/>Scrapers</button>
          </div>
          <Link to='/listings-tool/scraper_requests'>
            <button className='mr-10 bg-lime-500 text-black p-1.5 rounded-sm flex items-center'>
            <DiDebian className='h-5 w-5 mr-1 animate-spin'/>Downloads
            </button>
          </Link>
        </div>
        <div className='w-[99%] m-4 h-full'>
          {view === 'WorkSpace' ? <Workspace/> :
            view === 'ScraperView' ? <BrandScrapersTable/> : ''}
        </div>
      </div>
      </div>
    </div>
  )
}

export default WebScrapers



