import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import axios from "axios"

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Input, Typography } from "@material-tailwind/react"

export function AddScraper({ handleCallBackState }) {
    const [open, setOpen] = useState(false)
    const [scraperData, setScraperData] = useState({
        scraperId: '',
        name: '',
        category: '',
        scrapeBy: 'Style Number'
    })

    const handleOpen = () => setOpen(!open)

    const handleAddScraper = () => {
        const { scraperId, name, category } = scraperData;
        if (!scraperId || !name || !category) {
            window.alert('Please enter all the required fields before submitting.');
            return
        }

        axios.post(`/api/webscrapers/add`, scraperData)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                setScraperData('')
                setOpen(!open)
                handleCallBackState()
            } else {
                toast.error(`${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error)
            const errorMessage = error.response?.data?.message || 'An error occurred during add.'
            toast.error(errorMessage)
        })
    }

    return (
        <>
            <Button onClick={handleOpen} variant="gradient" color="blue">
                Add
            </Button>
            <Dialog 
                className="bg-white" size="sm"
                open={open}
                handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
            >
            <DialogHeader className="text-white bg-blue-500 rounded-t-md">Add Scraper</DialogHeader>
            <DialogBody className="flex flex-col gap-4 items-center justify-center">
                <div className="w-72">
                    <Typography color="black" >Id</Typography>
                    <Input 
                        required
                        onChange={e => setScraperData({ ...scraperData, scraperId: e.target.value })}
                        label="Enter Scraper Id" 
                        color="blue"/>
                </div>
                <div className="w-72">
                    <Typography color="black">Name</Typography>
                    <Input 
                        required
                        onChange={e => setScraperData({ ...scraperData, name: e.target.value })}
                        label="Enter Scraper Name" 
                        color="blue"/>
                </div>
                <div className="w-72">
                    <Typography color="black">Category</Typography>
                    <Input 
                        required
                        onChange={e => setScraperData({ ...scraperData, category: e.target.value })}
                        label="Enter Scraper Category" 
                        color="blue"/>
                </div>
                <div className="w-72">
                    <Typography color="black">Scrape by</Typography>
                    <Input 
                        onChange={e => setScraperData({ ...scraperData, scrapeBy: e.target.value })}
                        label="Enter Scrape by" 
                        color="blue"/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    color="gray"
                    onClick={handleOpen}
                    className="mr-1"
                >
                <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="blue" onClick={handleAddScraper}>
                    <span>Add</span>
                </Button>
            </DialogFooter>
            </Dialog>
        </>
    );
}