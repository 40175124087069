import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from 'axios';

const exportResults = async ({ batchId, brand, siteCategory }) => {
    try {
        const scraperDataResponse = await axios.get(`/api/scraper-results/batch/${batchId}`);
        const scraperDataResult = scraperDataResponse.data.results.results;

        console.log(scraperDataResult);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Scraped Results');

        // Extract headers dynamically from all items in all results
        const headers = [
            ...new Set(
                scraperDataResult.flatMap(item =>
                    item.result.flatMap(result => Object.keys(result || {}))
                )
            ),
        ];

        // Add headers to the worksheet
        worksheet.columns = headers.map(header => ({ header, key: header, width: 40 }));

        // Add rows dynamically by iterating over each item and each result within item.result
        scraperDataResult.forEach(item => {
            item.result.forEach(result => {
                const rowData = headers.map(header => {
                    const value = result[header];

                    // Handle array data for multiline display
                    if (Array.isArray(value)) {
                        return value.join('\n'); 
                    }
                    return value || '';
                });
                worksheet.addRow(rowData);
            });
        });

        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = { wrapText: false, vertical: 'top' };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const fileName = `${batchId}_${brand}-${siteCategory}.xlsx`;
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, fileName);
        console.log(`File downloaded as ${fileName}`);
    } catch (error) {
        console.error('Error exporting results:', error);
    }
};

export default exportResults;
