import React from 'react';

const StatusSelect = ({ status, onStatusChange }) => {
    return (
        <select 
            id="small_select" 
            className="border border-gray-300 text-gray-600 rounded-lg py-1 focus:outline-none"
            defaultValue={status}
            onChange={(e) => onStatusChange(e.target.value)} 
        >
            <option value="Active" selected hidden>Select Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Maintenance">Maintenance</option>
        </select>
    );
};

export default StatusSelect;
