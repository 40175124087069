import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import ExcelParse from '../../../../middlewares/GlobalUtils/excelReader'
import generateTimestamp from '../../../../middlewares/GlobalUtils/generateTimeStamp'
import { useUser } from '../../../../store/userState'

import { Tooltip } from '@material-tailwind/react'
import DownloadImportBtn from './DownloadImportBtn'
import { toast } from 'react-toastify'

import { FaArrowRightLong } from "react-icons/fa6"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { AiFillAmazonSquare } from "react-icons/ai"
import { TbWashDrycleanOff } from "react-icons/tb"
import { InformationCircleIcon } from '@heroicons/react/24/solid'

const Workspace = () => {
    const { user } = useUser()

    const [brandScraperData, setBrandScraperData] = useState([])
    const [requestData, setRequestData] = useState({
        siteCategory: '',
        scraperId: '',
        brandName: '',
        importData: ''
    })

    brandScraperData.sort((a, b) => a.scraperId.localeCompare(b.scraperId))
    const filteredBrandScrapers = brandScraperData.filter(item => item.category === requestData.siteCategory)

    const submitDataChecker = requestData.siteCategory && requestData.scraperId && requestData.importData

    const fileInputRef = useRef(null)

    useEffect(() => {
        axios.get(`/api/webscrapers/all/scrapers`)
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    setBrandScraperData(response.data.scrapers)
                    console.log(response.data.scrapers)
                }
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [])

    const updateRequestData = (fields) => {
        setRequestData(prevData => {
            const updatedData = { ...prevData, ...fields }
            console.log("Updated requestData:", updatedData)
            return updatedData
        })
    }

    const clearDataSelection = () => {
        setRequestData(prevData => {
            return Object.fromEntries(
                Object.keys(prevData).map(key => [key, ''])
            );
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }

        const radioButtons = document.querySelectorAll('input[type="radio"]');
        radioButtons.forEach(radio => (radio.checked = false));

        const textareas = document.querySelectorAll('textarea');
        textareas.forEach(textarea => {
            textarea.value = '';
        });
    }
    
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result; 
                const parsedData = await ExcelParse(arrayBuffer);
                
                updateRequestData({ importData: parsedData });
            };
            reader.readAsArrayBuffer(file); 
        }
    };

    const handleTextareaChange = (e) => {
        const input = e.target.value;

        const formattedArray = input
            .split(/[\n,]+/)           // Split by newline or comma
            .map(item => item.trim())  // Trim each entry
            .filter(item => item);     // Remove empty strings

        updateRequestData({ importData: formattedArray });
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }

    const handleSubmitRequest = () => {
        const requestImport = {
            batchId: generateTimestamp(),
            siteCategory: requestData.siteCategory,
            scraperId: requestData.scraperId,
            brandName: requestData.brandName,
            importData: requestData.importData,
            addedBy: user.email,
        };
        
        axios.post(`/api/scraper-requests/add`, requestImport)
            .then(response => {
                if (response.data.message) {
                    toast.success(`${response.data.message}`);
                    clearDataSelection();
                    // Trigger the startWatcher API
                    axios.post(`/api/scraper-watcher/start-watching`)
                        .then(watcherResponse => {
                            if (watcherResponse.data.message) {
                                //toast.info('Watcher started successfully.');
                            }
                        })
                        .catch(watcherError => {
                            console.error('Error starting watcher:', watcherError);
                            const watcherErrorMessage = watcherError.response?.data?.message || 'Failed to start watcher.';
                            console.log(watcherErrorMessage)
                            toast.error(watcherErrorMessage);
                        });
                } else {
                    toast.error(`${response.data.message}`);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during add.';
                toast.error(errorMessage);
            });
    };

    return (
        <div className='h-full w-full flex flex-col gap-5 items-center justify-center text-white font-semibold'>
            <div className='flex flex-row items-center justify-evenly gap-4 bg-black bg-opacity-50 p-3 rounded-md w-[55%]'>
                {/* Site Category */}
                <p className='flex items-center'>
                    {requestData.siteCategory ? (
                        <p>Site category: <span className={`${requestData.siteCategory === 'public' ? 'text-yellow-500' : 'text-cyan-300'}`}>
                            {requestData.siteCategory === 'public' ? capitalizeFirstLetter(requestData.siteCategory) : requestData.siteCategory.toUpperCase()}
                        </span></p>
                    ) : 'Site Category'}
                    <IoMdCheckmarkCircleOutline className={`h-5 w-5 ml-1 ${requestData.siteCategory ? 'text-green-400' : ''}`} />
                </p>

                <FaArrowRightLong className={`h-5 w-5 ${requestData.brandName ? 'text-green-500' : 'animate-pulse text-orange-500'}`} />

                {/* Brand Scraper */}
                <p className='flex items-center'>
                    {requestData.brandName ? (
                        <p className='flex items-center'>Brand scraper: {requestData.brandName}  
                        <AiFillAmazonSquare className={`ml-1 h-5 w-5 ${requestData.siteCategory === 'public' ? 'text-yellow-500' : 'text-cyan-300'}`}/></p>
                    ) : 'Brand Scraper'}
                    <IoMdCheckmarkCircleOutline className={`h-5 w-5 ml-1 ${requestData.brandName ? 'text-green-400' : ''}`} />
                </p>

                <FaArrowRightLong className={`h-5 w-5 ${requestData.importData ? 'text-green-500' : 'animate-pulse text-orange-500'}`} />

                {/* File Import */}
                <p className='flex items-center'>
                    Data import
                    <IoMdCheckmarkCircleOutline className={`h-5 w-5 ml-1 ${requestData.importData ? 'text-green-400' : ''}`} />
                </p>
                
                <div className='flex flex-row items-center gap-2 '>
                    <Tooltip content="Clear selection">
                        <button onClick={clearDataSelection} className='p-1 px-2 flex items-center text-center bg-red-500 rounded-md'><TbWashDrycleanOff className='h-5 w-5'/></button>
                    </Tooltip>
                    <button  onClick={handleSubmitRequest} className={`p-1 px-2 flex items-center text-center bg-gray-500 rounded-md cursor-not-allowed
                        ${submitDataChecker ? 'bg-green-500 cursor-pointer' : ''}`} disabled={!submitDataChecker}>
                        Submit
                    </button>
                </div>
                
            </div>

            <div className='w-full h- flex flex-wrap gap-10 justify-center items-center mt-10'>
                {/* Site Category Section */}
                <div className='p-5 bg-white bg-opacity-15 w-[30%] h-[45vh] rounded-xl flex flex-col items-center border-2 backdrop-filter backdrop-blur-sm'>
                    <p className='font-semibold text-2xl'>Select Site Category</p>
                    <div className='flex flex-col gap-5 h-full w-full items-center justify-center'>
                        <label className="relative flex items-center cursor-pointer">
                            <input
                                className="sr-only peer"
                                name="siteCategory"
                                type="radio"
                                onChange={() => updateRequestData({ siteCategory: 'public' })}
                            />
                            <div className="w-8 h-8 bg-transparent border-2 border-yellow-500 rounded-full peer-checked:bg-yellow-500 peer-checked:border-yellow-500 peer-hover:shadow-lg peer-hover:shadow-yellow-500/50 peer-checked:shadow-lg peer-checked:shadow-yellow-500/50 transition duration-300 ease-in-out"></div>
                            <span className="ml-2 text-white text-2xl">Public Site</span>
                        </label>
                        <label className="relative flex items-center cursor-pointer">
                            <input
                                className="sr-only peer"
                                name="siteCategory"
                                type="radio"
                                onChange={() => updateRequestData({ siteCategory: 'b2b' })}
                            />
                            <div className="w-8 h-8 bg-transparent border-2 border-cyan-300 rounded-full peer-checked:bg-cyan-300 peer-checked:border-cyan-300 peer-hover:shadow-lg peer-hover:shadow-cyan-300/50 peer-checked:shadow-lg peer-checked:shadow-cyan-300/50 transition duration-300 ease-in-out"></div>
                            <span className="ml-2 text-white text-2xl">B2B Site</span>
                        </label>
                    </div>
                </div>

                {/* Brand Scraper Section */}
                <div className='p-5 bg-white bg-opacity-15 w-[30%] h-[45vh] rounded-xl flex flex-col gap-2 items-center backdrop-filter backdrop-blur-sm border-2'>
                    <p className='font-semibold text-2xl text-white'>Select Brand Scraper</p>
                    <div className='p-0.5 h-[95%] w-full rounded-md bg-opacity-30 flex flex-wrap items-center justify-center gap-4 overflow-x-auto overflow-y-auto'>
                        {filteredBrandScrapers
                            .filter((item) => item.status === 'Active')
                            .map((item) => {
                            
                            const brandName = item.name.split('-')[0]
                            return (
                                <button
                                    key={item.scraperId}
                                    className='p-2 bg-[#505f75] min-w-36 max-w-fit text-center rounded-md flex items-center justify-center hover:bg-green-500 
                                        transition ease-in-out text-xs'
                                    onClick={() => updateRequestData({ scraperId: item.scraperId, brandName })}
                                >
                                    {brandName} <AiFillAmazonSquare className={`ml-1 h-5 w-5 ${item.category === 'public' ? 'text-yellow-500' : 'text-cyan-300'}`} />
                                </button>
                            )
                        })}
                    </div>
                </div>

                {/* File Import Section */}
                <div className='p-5 bg-white bg-opacity-15 w-[30%] h-[45vh] rounded-xl flex flex-col items-center backdrop-filter backdrop-blur-sm border-2'>
                    <div className='flex flex-row gap-3 items-center'>
                        <p className='font-semibold text-2xl text-white'>Data Import</p>
                        <Tooltip 
                        className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                        content={
                            <div className="p-1 text-black flex flex-col ">
                                <p className='font-semibold'>Input by:</p>
                                <p>• Style #</p>
                                <p>• Style Name</p>
                                <p>• Keywords(eg. shoes, socks, jacket)</p>
                            </div>
                            }>
                            <InformationCircleIcon className='h-6 w-6 text-blue-400 animate-pulse'/>
                        </Tooltip>
                    </div>
                    <div className='flex flex-col gap-4 h-full w-full items-center justify-center'>
                        <textarea
                            className="block w-full h-32 px-4 py-2 text-sm font-normal shadow-xs text-white bg-transparent border border-gray-300 rounded-2xl placeholder-gray-400 focus:outline-none resize-none leading-relaxed"
                            placeholder="Enter by line or separate by comma"
                            onChange={handleTextareaChange}
                        />
                        <p>or</p>
                        <div className="w-full py-5 rounded-2xl border border-gray-300 gap-3 grid border-dashed">
                            <div className="flex flex-col justify-center items-center gap-2">
                                <h4 className="text-center text-white text-sm font-medium leading-snug">Import file here</h4>
                                <div className="flex items-center justify-center">
                                    <label>
                                        <input type="file" hidden ref={fileInputRef} onChange={handleFileChange} />
                                        <div className="flex w-28 h-9 px-2 flex-col bg-indigo-600 rounded-full shadow text-white text-xs font-semibold leading-4 items-center justify-center cursor-pointer focus:outline-none">Choose File</div>
                                    </label>
                                    
                                </div>
                                <DownloadImportBtn/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Workspace
