import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';

import { Card, Progress, Tooltip, Typography } from "@material-tailwind/react";
import { HiTrash } from "react-icons/hi2"
import { BiLoaderCircle } from "react-icons/bi";
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CheckCircleIcon, ExclamationCircleIcon, 
    ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/solid"
import exportResults from '../../../../middlewares/Listings/exportResults';
import Pagination from '../../../../Components/Pagination';
import SearchInput from '../../../../Components/SearchInput';

const TABLE_HEAD = ["Request Id", "Uploaded by", "Site Category", "Brand", "Status", "Actions"];

export function RequestsTable() {

    const [requestData, setRequestData] = useState([])
    const [isAltered, setIsAltered] = useState(false)
    const [isAltereds, setIsAltereds] = useState()
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [downloadingBatch, setDownloadingBatch] = useState({});

    const requestsPerPage = 10
    const totalPages = Math.ceil(requestData.length / requestsPerPage)
    const indexOfLastRequest = currentPage * requestsPerPage
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage

    const filteredRequests = requestData.filter(item =>
        (item.batchId && item.batchId.toString().includes(searchTerm.trim())) ||
        (item.addedBy && item.addedBy.toString().includes(searchTerm.trim())) ||
        (item.brandName && item.brandName.toLowerCase().includes(searchTerm.toLowerCase())) 
    )

    filteredRequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    const requestDataArr = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest)

    useEffect(() => {
        axios.get(`/api/scraper-requests/getRequests`)
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get batches")
            } else {
                setRequestData(response.data.requests)
            } 
        })
        .catch(error => {
            console.error('Error:', error)
        }) 

    }, [isAltered, isAltereds])

    useEffect(() => {
        const toggleInterval = setInterval(() => {
            setIsAltereds(prevState => !prevState)
        }, 5000) 
        return () => {
            clearInterval(toggleInterval)
        }
    }, [])

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const handleDeleteRequest = (batchId) => {
        const isConfirmed = window.confirm(`Are you sure you want to this batch: ${batchId}?`)

        if (isConfirmed) {
            axios.delete(`/api/scraper-requests/delete/${batchId}`)
                .then(response => {
                    if (response.data && response.data.message) {
                        toast.success(response.data.message)
                        axios.delete(`/api/scraper-results/delete/${batchId}`)
                        .then(response => {
                            console.log(response.data)
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        })
                    } else {
                        toast.error('Deletion was unsuccessful. Please try again.')
                    }
                    setIsAltered(prevState => !prevState)
                })
                .catch(error => {
                    toast.error('An error occurred while deleting the scraper.')
                    console.error('Error:', error)
                })
        }
    }

    const resetProgress = (batchId) => {
        axios.put(`/api/scraper-requests/update/progress/${batchId}`, { progress: 0 })
            .then(response => {
                const message = response.data.message || "Progress reset successfully.";
                //toast.success(message);
                console.log(`reset progress: ${batchId}`);
            })
            .catch(error => {
                toast.error("Failed to reset progress");
                console.error('Error:', error);
            });
    };

    const handleReopenRequest = (batchId) => {

        const updatedStatus = {
            status: {
                name: 'Open',
                message: 'This batch is reopened'
            }
        }
        axios.put(`/api/scraper-requests/update/status/${batchId}`, updatedStatus)
        .then( response => {
            console.log(response)
            if (response.data.message){
                toast.success('Batch reopened')
                resetProgress(batchId)
                axios.delete(`/api/scraper-results/delete/${batchId}`)
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                })
                axios.post(`/api/scraper-watcher/start-watching`)
                .then(watcherResponse => {
                    if (watcherResponse.data.message) {
                        //toast.info('Watcher started successfully.')
                        console.log('Watcher started successfully')
                    }
                })
                .catch(watcherError => {
                    console.error('Error starting watcher:', watcherError);
                    const watcherErrorMessage = watcherError.response?.data?.message || 'Failed to start watcher.';
                    console.log(watcherErrorMessage)
                    //toast.error(watcherErrorMessage);
                });
            } else {
                toast.error(response.data.message)
            }
        })
        .catch(error => {
            toast.error(error)
            console.error('Error:', error)
        })
    }

    const handleDownloadRequest = async (batchId, brand, siteCategory) => {
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: true }));
        await exportResults({ batchId, brand, siteCategory })
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: false }));
    }

    return (
    <div className="flex flex-col w-full h-full">

        <div className='flex items-center justify-between text-white'>
            <SearchInput  value={searchTerm} onChange={handleSearch}/> 
            <p className="mr-5">Total Requests: <strong className='text-blue-400'>{requestData.length}</strong></p>
        </div>


        <Card className="h-[37rem] w-full bg-[#101927] bg-opacity-50 rounded-b-none overflow-y-auto">
            <table className="w-full min-w-max table-auto text-center">
            <thead>
                <tr>
                {TABLE_HEAD.map((head) => (
                    <th key={head} className="border-b border-[#374050] bg-[#1E2936] p-4 sticky top-0 z-10">
                    <Typography
                        variant="small"
                        color="white"
                        className="font-normal leading-none opacity-70"
                    >
                        {head}
                    </Typography>
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                {requestDataArr.map((item) => {
                const date = new Date(item.createdAt)
                const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
                        
                const options = { month: 'long', day: 'numeric', year: 'numeric' }
                const formattedDate = date.toLocaleDateString('en-US', options)
                const formattedDateTime = `${formattedDate} ${formattedTime}`

                return (
                    <tr key={item.batchId} className="even:bg-blue-gray-50/50 hover:bg-blue-400">
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal">
                        {item.batchId}
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal flex items-center justify-center">
                        {item.addedBy} 
                        <Tooltip content={formattedDateTime}>
                            <InformationCircleIcon className='ml-1 h-5 w-5'/>
                        </Tooltip>
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-normal">
                        {item.siteCategory}
                    </Typography>
                    </td>
                    <td className="p-4">
                    <Typography variant="small" color="white" className="font-medium">
                        {item.brandName}
                    </Typography>
                    </td>
                    {/* <td className="p-4">
                        <Tooltip 
                        className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                        content={
                            <div className="p-1 text-black">
                                {item.importData.map((data, index) => (
                                <div key={index}>• {data}</div>
                                ))}
                            </div>
                            }
                        >
                            <button className='text-white'><PiBracketsCurlyFill className='h-7 w-7'/></button>
                        </Tooltip>
                    </td> */}
                    <td className="p-4 flex flex-col">
                    <Typography  variant="small" color="white" className="font-normal flex items-center justify-center">
                    {item.status.name} {item.progress}%
                    <Tooltip content={item.status.message}>
                    {item.status.name === 'Open' ? <InformationCircleIcon className='h-5 w-5 ml-1 text-blue-500'/> :
                        item.status.name === 'Processing' ?  <ArrowPathIcon className='h-5 w-5 ml-1 animate-spin text-orange-500'/> :
                        item.status.name === 'Complete' ?  <CheckCircleIcon className='h-5 w-5 ml-1 text-green-500'/> : 
                        item.status.name === 'Error' ?  <ExclamationTriangleIcon className='h-5 w-5 ml-1 text-red-500'/> : ''}
                    </Tooltip>
                    </Typography>
                    <Progress value={item.progress} variant='gradient' size='sm' 
                        className='text-black' 
                        color={`${item.progress !== 100 ? 'orange' : 'green'}`}/>
                    </td>
                    <td className="p-4">
                    {item.status.name !== 'Open' ? 
                    <>
                        {item.status.name !== 'Processing' ? 
                        <button className='p-1 mx-1 bg-blue-500 rounded-md text-white' onClick={() => handleDownloadRequest(item.batchId, item.brandName, item.siteCategory)}>
                        {downloadingBatch[item.batchId] ? <BiLoaderCircle className='h-5 w-5 animate-spin'/> : <ArrowDownTrayIcon className="h-5 w-5" />}
                        </button>
                        : ''
                        } 
                        <button className='p-1 mx-1 bg-orange-500 rounded-md text-white' onClick={() => handleReopenRequest(item.batchId)}>
                            <ArrowPathRoundedSquareIcon className='h-5 w-5'/>
                        </button>
                    </>
                    : ''}
                        <button className='p-1 mx-1 bg-red-500 rounded-md text-white' onClick={() => handleDeleteRequest(item.batchId)}>
                            <HiTrash className='h-5 w-5'/>
                        </button>
                    </td>
                </tr>
                )

                })}
            </tbody>
            </table>
        </Card>
        <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
            <Pagination
                currentPage={currentPage}
                customClass={`bg-opacity-50`}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
            />
        </div>
    </div>

    );
}
