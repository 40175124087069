import React from 'react'

import neural from '../../../../assets/neural-networks.webp'

import FloatBar from '../../../../Components/FloatBar'
import SideButton from '../../../../Components/SideButton'
import { ToastContainer } from 'react-toastify'
import { RequestsTable } from './RequestsTable'

const ScraperRequestPage = () => {
  return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-y-auto'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.60), rgba(17, 24, 38, 0.8)), url(${neural})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

      <FloatBar/>
      <div className='flex flex-row gap-2 w-full h-full'>
      <SideButton/>
      <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
        <ToastContainer/>
        <div className='w-full h-fit flex justify-center p-4 text-white'>
          <p className=' text-xl'>Scraper Request Table</p>
        </div>
        <div className='w-[99%] m-4 h-full'>
            <RequestsTable/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default ScraperRequestPage
