const XLSX = require('xlsx');

const ExcelParse = async (file) => {

    const workbook = XLSX.read(file, { type: 'buffer' });

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    // Convert the worksheet to JSON format as a 2D array and skip the header row
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });

    // Skip the first row (header) and retrieve only the values in the first column
    const result = jsonData.slice(1).map(row => row[0]);

    return result;
};

export default ExcelParse;
